<template>
	<div class="main-content">
		<ktv-breadcrumb
			:title="' Form'"
			page="Users"
			folder="System"
		/>
		<div>
			<ktv-back @click="backToList()" />
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor title="Users Data" name="useraccor" :footer="opsiDisplay !== 'View'">
						<template #content>
							<b-row>
								<b-col v-show="loading" cols="6">
									<vcl-list class="mt-4" /><vcl-list class="mt-4" />
								</b-col>
								<b-col v-show="loading" cols="6">
									<vcl-list class="mt-4" /><vcl-list class="mt-4" />
								</b-col>
								<b-col v-show="!loading">
									<b-row>
										<b-col cols="10">
											<b-form-group label="Full Name">
												<b-form-input v-model="form.UserRealName" v-validate="'required'"
													:disabled="disabledField" name="UserRealName" :state="validateState('UserRealName')" data-vv-as="FullName"
													type="text"
												/>
												<b-form-invalid-feedback>
													{{ veeErrors.first('UserRealName') }}
												</b-form-invalid-feedback>
											</b-form-group>
										</b-col>
										<b-col cols="2">
											<b-form-group label="button" class="text-white">
												<win-entity
													v-if="!disabledField"
													@selected="selectedEntity"
												/>
												<ktv-button
													v-if="disabledField"
													style="padding-left:5px;padding-right:5px"
													icon="remove"
													color="danger"
													tooltip="remove"
													@click="removeFullName()"
												/>
											</b-form-group>
										</b-col>
									</b-row>
									<b-form-group label="Partner">
										<b-form-select v-model="form.PartnerID"
											:options="optionPartnerUserFetch" name="PartnerID" :state="validateState('PartnerID')" 
										/> 
									</b-form-group>
									<b-form-group label="Username">
										<b-form-input v-model="form.UserName" 
											v-validate="'required'" name="UserName" :state="validateState('UserName')" 
											type="text"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('UserName') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="Email">
										<b-form-input v-model="form.Email" 
											v-validate="'required|email'" name="Email" :state="validateState('Email')" data-vv-as="email"
											type="text"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('Email') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<!-- <b-form-group label="Password">
									<b-form-input ref="password" 
										v-model="form.UserPassword" v-validate="'required'" name="UserPassword" :state="validateState('UserPassword')"
										type="password"
									/>
									<b-form-invalid-feedback>
										{{ veeErrors.first('UserPassword') }}
									</b-form-invalid-feedback>
								</b-form-group>
								<b-form-group label="Re-type Password">
									<b-form-input v-model="form.UserPasswordRe" 
										v-validate="'required|confirmed:password'" name="UserPasswordRe" :state="validateState('UserPasswordRe')" data-vv-as="Retype Password"
										type="password"
									/>
									<b-form-invalid-feedback>
										{{ veeErrors.first('UserPasswordRe') }}
									</b-form-invalid-feedback>
								</b-form-group> -->
									<b-form-group label="Work Phone">
										<b-form-input v-model="form.WorkPhone" 
											v-validate="'required'" name="WorkPhone" :state="validateState('WorkPhone')" data-vv-as="Phone"
											type="text"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('WorkPhone') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								<b-col v-show="!loading">
									<b-form-group label="Gender">
										<b-form-radio-group v-model="form.Gender" v-validate="'required'" :options="optionGender" 
											class="mt-1" name="Gender" :state="validateState('Gender')"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('Gender') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="Country">
										<b-form-select v-model="form.CountryID" v-validate="'required'" 
											:options="optionCountry" name="CountryID" :state="validateState('CountryID')" data-vv-as="Country"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('CountryID') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="Language">
										<b-form-select v-model="form.UserLanguage" 
											v-validate="'required'" name="UserLanguage" :state="validateState('UserLanguage')" data-vv-as="Language"
											:options="optionLanguage"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('UserLanguage') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="Role">
										<b-form-select v-model="form.RoleId"
											v-validate="'required'" name="RoleId" :state="validateState('RoleId')" data-vv-as="Role"
											:options="optionRole" @change="changeRole()"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('RoleId') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group label="User Is Admin">
										<b-form-radio-group v-model="form.UserIsAdmin" 
											v-validate="'required'" name="UserIsAdmin" :state="validateState('UserIsAdmin')" data-vv-as="User is Admin"
											:options="optionYesNo" class="mt-2"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('UserIsAdmin') }}
										</b-form-invalid-feedback>
									</b-form-group>
									<b-form-group class="mt-2" label="Status">
										<b-form-radio-group v-model="form.StatusCd" 
											v-validate="'required'" name="StatusCd" :state="validateState('StatusCd')" data-vv-as="Status"
											:options="optionStatus" class="mt-2"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('StatusCd') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
								<b-col v-show="!loading" cols="12" class="mt-4 mb-4">
									<label class="label-bold">User Group</label>&nbsp;&nbsp;&nbsp;<div v-if="loadingGroups" class="spinner sm spinner-primary" style="height:15px;width:15px" />
									<v-multiselect-listbox
										v-if="optionGroups.length"
										v-model="form.Groups"
										style="width: 100%"
										:options="optionGroups"
										:reduce-display-property="(option) => option.text"
										:reduce-value-property="(option) => option.value"
										no-options-found-text="No data found"
										no-selected-options-found-text="No selected data found"
									/>
									<v-multiselect-listbox
										v-if="!optionGroups.length"
										style="width: 100%"
										:options="[]"
										:reduce-display-property="(option) => option.text"
										:reduce-value-property="(option) => option.value"
										no-options-found-text="No data found"
										no-selected-options-found-text="No selected data found"
									/>
								</b-col>
								<b-col v-show="!loading" cols="6">
									<b-form-group label="User Group Default">
										<b-form-select v-model="form.UserGroupIsDefault"
											v-validate="'required'" name="UserGroupIsDefault" :state="validateState('UserGroupIsDefault')" data-vv-as="Group Default"
											:options="optionGroupDef" :disabled="!optionGroupDef.length"
										/>
										<b-form-invalid-feedback>
											{{ veeErrors.first('UserGroupIsDefault') }}
										</b-form-invalid-feedback>
									</b-form-group>
								</b-col>
							</b-row>
						</template>
						<template #footerright>
							<ktv-button
								text="Save"
								:loading="loading"
								color="green-dark"
								icon="save"
								@click="save()"
							/>
						</template>
					</ktv-accor>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from 'vuex'
	import { KtvButton, KtvAccor } from "@/components"
	import WinEntity from "./WinEntity"
	export default {
		metaInfo: {
			title: "Users Form",
		},
		components: { 
			KtvButton,
			WinEntity,
			KtvAccor
		},
		data() {
			return {
				form: {
					UserId: "",
					UserRealName: "",
					UserName: "",
					UserActive: "",
					UserIsAdmin: "",
					UserLanguage: "",
					RoleId: "",
					Gender: "",
					WorkPhone: "",
					CountryID: "",
					Email: "",
					UserGroupIsDefault: "",
					Groups: [],
					StatusCd: "",
					EntityID: "",
					UserPassword: "",
					UserPasswordRe: "",
					PartnerID: ""
				},
				loading: true,
				disabledField: false,
				optionStatus: [
					{
						text: "Active",
						value: "active",
					},
					{
						text: "Inactive",
						value: "inactive",
					},
					{
						text: "Nullified",
						value: "nullified",
					},
				],
				optionGroups: [],
				optionGender: [
					{
						text: "male",
						value: "m"
					},
					{
						text: "female",
						value: "f"
					},
				],
				loadingGroups: false,
				optionPartnerUserFetch:[]
			}
		},
		computed: {
			...mapGetters({
				optionCountry: "MASTER/getCountry",
				optionLanguage: "MASTER/getLanguage",
				optionYesNo: "MASTER/getYesNo",
				optionRole: "MASTER/getRole",
				optionPartnerUser: "MASTER/getPartnerUser",
				opsiDisplay: "USERS/getOpsiDisplay",
			}),
			optionGroupDef() {
				return this.optionGroups.filter((item) => this.form.Groups.includes(item.value))
			},
		},
		async mounted() { 
			await this.loadOptions()
			await this.fetchData()
		},
		methods: {
			...mapActions("USERS", ["actGetDetail","actPutData","actPostData","actDeleteData"]),
			...mapActions("MASTER", ["actGetGender","actGetCountry","actGetLanguage","actGetYesNo","actGetRole", "actGetPartnerUser"]),
			removeFullName() {
				this.form.EntityID = "";
				this.form.UserRealName = "";
				this.disabledField = false;
			},
			selectedEntity(e) {
				this.form.EntityID = e.EntityID;
				this.form.UserRealName = e.PersonName;
				this.disabledField = true;
			},
			save() {
				this.$validator.validateAll().then(result => {
					if (!result) {
						return;
					}

					if (this.opsiDisplay === 'Update') {
						this.$swal({
							title: 'Are you sure?',
							text: "Data will be updated.",
							type: 'question',
							showCancelButton: true,
							confirmButtonText: 'Submit',
							showLoaderOnConfirm: true,
							preConfirm: () => {
								return this.actPutData(this.form).then((datares) => {
									return datares;
								})
							},
							allowOutsideClick: () => !this.$swal.isLoading()
						}).then((res) => {
							if (res.value.success) {
								this.$swal('Success!', 'Data has been updated.', 'success').then(() => {
									this.fetchData()
								})
							} else {
								this.$swal('Error!', 'Data failed to be updated.', 'error')
							}
						})
					} else if (this.opsiDisplay === 'Add') {
						this.$swal({
							title: 'Are you sure?',
							text: "Data will be saved.",
							type: 'question',
							showCancelButton: true,
							confirmButtonText: 'Submit',
							showLoaderOnConfirm: true,
							preConfirm: () => {
								return this.actPostData(this.form).then((datares) => {
									return datares;
								})
							},
							allowOutsideClick: () => !this.$swal.isLoading()
						}).then((res) => {
							if (res.value.success) {
								this.$swal('Success!', 'Data has been saved.', 'success')
									.then(() => {				
										this.$router.back()
									})
							} else {
								this.$swal('Error!', 'Data failed to be saved.', 'error')
							}
						})
					}
				});
			},
			setDataPartner(){
				const newArr = [{'text': '--Choose Partner--', 'value' : ''}];
				this.optionPartnerUser.forEach(element => {
					const arr = {'text': element.text, 'value' : element.value}
					newArr.push(arr)
				});  
				this.optionPartnerUserFetch = newArr
			},
			async fetchData() {
				this.loading = true;
				this.setDataPartner()
				if (this.opsiDisplay === 'View' || this.opsiDisplay === 'Update') {
					const res = await this.actGetDetail()
					this.form = res.results
					if (this.form.EntityID !== "" && this.form.EntityID !== null && this.form.EntityID !== undefined) {
						this.disabledField = true
					} else {
						this.disabledField = false
					}
					this.changeRole(this.form.RoleId)
					this.loading = false
					return res;
				} 
				this.form = {
					UserId: "",
					UserRealName: "",
					UserName: "",
					UserActive: "",
					UserIsAdmin: "",
					UserLanguage: "",
					RoleId: "",
					Gender: "",
					WorkPhone: "",
					CountryID: "",
					Email: "",
					UserGroupIsDefault: "",
					Groups: [],
					StatusCd: "",
					EntityID: "",
					UserPassword: "",
					UserPasswordRe: "",
					PartnerID: ""
				};
				this.loading = false
				return null;				
			},
			async loadOptions() {
				this.actGetCountry()
				this.actGetLanguage()
				this.actGetYesNo()
				this.actGetRole()
				this.actGetPartnerUser()
			},
			changeRole() {
				this.loadingGroups = true;
				this.optionGroups = []
				let payload = { roleId: this.form.RoleId };
				this.$http
					.Get(payload, this.$urlApi.role.groupList)
					.then((r) => {
						if (r.results && r.results.length && Array.isArray(r.results)) {
							this.optionGroups = r.results
							this.form.Groups = (this.form.Groups).filter(a => (this.optionGroups).some(b => a === (b.value)));
							this.loadingGroups = false;
						}
					})
					.catch((error) => {
						this.loadingGroups = false;
						this.$swal("Failed!", error.response.error_message, "error")
					})
			},
			backToList() {
				this.$router.back()
			},

		}

	}
</script>