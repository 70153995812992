<template>
	<div>
		<ktv-button
			style="padding-left:5px;padding-right:5px"
			icon="search"
			tooltip="find entity"
			color="green-light"
			@click="modalsReg = true"
		/>
		<!--modal User Entity-->
		<ktv-modal
			v-model="modalsReg"
			title="User Entity"
			size="lg"
			@close="modalsReg = false"
		>
			<template #content>
				<ktv-table
					:columns="columns"
					:is-loading="isLoading"
					:rows="rows"
					:total-rows="totalRecords"
					:mode="modeTable"
					:filter="false"
					:rows-per-page="serverParams.rowPerpage"
					search-placeholder="Search by Name"
					style="width:100%;"
					@on-column-filter="onColumnFilter"
					@on-page-change="onPageChange"
					@on-per-page-change="onPerPageChange"
					@on-search="onSearchFilter"
					@on-sort-change="onSortChange"
				>
					<template #rows="{ props }">
						<span v-if="props.column.field == 'button'">
							<i
								v-b-tooltip.hover
								class="nav-icon text-14 i-Add icon-choose"
								title="Choose"
								@click="choose(props.row)"
							/>
						</span>
					</template>
				</ktv-table>
			</template>
			<template #footer>
				<ktv-button
					text="Close"
					color="gray-light"
					class="float-right"
					@click="modalsReg = false"
				/>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { KtvButton, KtvModal, KtvTable } from "@/components"
	export default {
		components: {
			KtvButton,
			KtvModal,
			KtvTable
		},
		emits: ['selected'],
		data() {
			return {
				modalsReg: false,
				isLoading: false,
				modeTable: "remote",
				columns: [
					{
						label: "Action",
						field: "button",
						html: true,
						tdClass: "text-center",
						thClass: "text-center",
						sortable: false
					},
					{
						label: "Entity ID",
						field: "EntityID",
						thClass: "text-left",
					},
					{
						label: "Name",
						field: "PersonName",
						thClass: "text-left",
					}
				],
				rows: [],
				totalRecords: 0,
				serverParams: {
					columnFilters: {
						PersonName: "",
					},
					sort: [{
						field: "PersonName",
						type: "ASC"
					}],
					currentPage: 1,
					rowPerpage: 10,
				},
			}
		},
		methods: {
			choose(event) {
				this.modalsReg = false;
				this.$emit("selected", event);
			},
			updateParams(newProps) {
				this.serverParams = Object.assign({}, this.serverParams, newProps)
			},
			onPageChange(params) {
				this.updateParams({ currentPage: params.currentPage })
				this.loadItems()
			},
			onPerPageChange(params) {
				this.updateParams({ rowPerpage: params.currentPerPage })
				this.loadItems()
			},
			onSortChange(params) {
				this.updateParams({
					sort: [
						{
							type: params[0].type,
							field: params[0].field,
						},
					],
					currentPage: 1
				})
				this.loadItems()
			},
			onColumnFilter(params) {
				this.updateParams(params)
				this.loadItems()
			},
			onSearchFilter(event) {
				this.updateParams({
					columnFilters: { PersonName: event },
				})
				this.loadItems()
			},
			loadItems() {
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.user.listEntity)
					.then((response) => {
						this.isLoading = false
						if (response.success) {
							this.rows = response.results.data
							this.totalRecords = response.results.total
						} else {
							this.$swal("Error!", response.error_massage, "error")
						}
					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
		}
	}
</script>

<style scoped>
.icon-choose:hover {
    transition: transform .2s; /* Animation */
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    margin: 0;
}
</style>